<template>
  <div :key="this.$store.state.myAWSUser">
    <div v-show="Boolean(my_user.userARN)">
      <groupui-headline class="name" heading="h4">AWS User: <code class="arn red-text">{{ my_user.userARN }}</code></groupui-headline>
      <groupui-headline class="name" heading="h4">MFA: <code class="arn red-text">{{ my_user.mfa }}</code></groupui-headline>
      <div>
        <groupui-button @click="create_config()">Generate my .aws/config</groupui-button>
        <b-modal id="modal-1" theme="man" class="modal" ok-only="true" v-bind:class="{ darkmode: this.$store.state.darkmode }">
          <template #modal-title>
            <groupui-headline heading="h3" weight="bold" serif="false" >Generate AWS Local Config</groupui-headline>
          </template>
          <b-form-textarea
            id="aws_config"
            plaintext :value="this.aws_config"
            :key="this.aws_config"
            rows="3"
            max-rows="20"
            v-bind:class="{ darkmode: this.$store.state.darkmode }"
          >
          </b-form-textarea>
            <groupui-button @click.stop.prevent="copyTestingCode">Copy to Clipboard</groupui-button>
            <a id="config_download"
              v-bind:href="'data:application/txt;base64,'+ this.base64_aws_config"
              download="config"
              :key="this.base64_aws_config"
            >
              <groupui-button>Download</groupui-button>
            </a>
          <template #modal-footer>
            <groupui-button @click="$bvModal.hide('modal-1')">Ok</groupui-button>
          </template>
        </b-modal>
      </div>
    </div>
    <div class="text-center" v-if="loading">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </div>
    <div>
      <div>
        <div class="users">My AWS Accounts</div>
      </div>
      <groupui-table v-bind:class="{ darkmode: this.$store.state.darkmode }">
        <groupui-table-row header>
          <groupui-table-cell>Account ID</groupui-table-cell>
          <groupui-table-cell>Roles</groupui-table-cell>
          <groupui-table-cell>Description</groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row v-show="!accounts">
          <groupui-table-cell>No Data </groupui-table-cell>
          <groupui-table-cell></groupui-table-cell>
          <groupui-table-cell></groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row v-show="accounts" v-for="account of accounts" :key="account.accountId">
            <groupui-table-cell>{{account.accountId}}</groupui-table-cell>
            <groupui-table-cell >
              <groupui-list>
                <ul>
                  <li v-for="role of account.roles" :key="role">
                    <a v-bind:href="getSwitchRoleURL(role)">
                      <groupui-text class="red-text" size="caption" weight="bold">{{role}}</groupui-text>
                    </a>
                  </li>
                </ul>
              </groupui-list>
            </groupui-table-cell>
            <groupui-table-cell>{{account.description}}</groupui-table-cell>
        </groupui-table-row>
      </groupui-table>
    </div>
    <div>
      <groupui-text class="users" v-bind:class="{ darkmode: this.$store.state.darkmode }">My AWS Access Keys:</groupui-text>
      <groupui-table v-bind:class="{ darkmode: this.$store.state.darkmode }">
        <groupui-table-row header>
          <groupui-table-cell>Access Key ID</groupui-table-cell>
          <groupui-table-cell>Creation Date</groupui-table-cell>
          <groupui-table-cell>Last Time Used</groupui-table-cell>
          <groupui-table-cell>Status</groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row v-show="!accounts">
          <groupui-table-cell>No Data </groupui-table-cell>
          <groupui-table-cell></groupui-table-cell>
          <groupui-table-cell></groupui-table-cell>
          <groupui-table-cell></groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row v-show="my_user.accessKeys" v-for="access_key_obj of my_user.accessKeys" :key="access_key_obj.accessKey">
            <groupui-table-cell>{{access_key_obj.accessKey}}</groupui-table-cell>
            <groupui-table-cell>{{access_key_obj.createDate}}</groupui-table-cell>
            <groupui-table-cell>{{access_key_obj.lastUsedDate}}</groupui-table-cell>
            <groupui-table-cell>{{access_key_obj.status}}</groupui-table-cell>
        </groupui-table-row>
      </groupui-table>
    </div>
  </div>
</template>

<script>

import Analytics from '@aws-amplify/analytics';

export default {
  name: 'my_user',
  data() {
    return {
      loading: true,
      accounts: [],
      accounts_alias: [],
      my_user: {},
      aws_config: "",
      base64_aws_config: ""
    };
  },
  async created() {
    this.getData();

    Analytics.record('Access_user_page');

  },
  components: {},
  methods: {
    show () {
      this.$modal.show('aws_config_modal');
    },
    create_config() {
      this.aws_config = "[profile man-default]\n"
      var mfa = this.my_user.mfa
      for(var i=0; i< this.accounts.length; i++){
        for(var j=0; j<this.accounts[i].roles.length; j++){
          var role = this.accounts[i].roles[j]
          var account_identification
          if(this.accounts_alias[i].account.accountAlias === null) {
            account_identification = this.accounts_alias[i].account.accountId
          }
          else{
            account_identification = this.accounts_alias[i].account.accountAlias
          }
          var role_lowercase
          if(role.includes("/man")){
            role_lowercase = role.split("/")[2].toLowerCase()
          }
          else{
            role_lowercase = role.split("/")[1].toLowerCase()
          }
          var profile = "\n[profile "+ account_identification+"-"+role_lowercase+"]\noutput = json\nregion = eu-central-1\nrole_arn = "+role+"\nsource_profile = man-default\nmfa_serial = "+mfa+"\n"
          this.aws_config += profile
        }
      }
      this.base64_aws_config = btoa(this.aws_config)
      this.$bvModal.show('modal-1')
    },
    async getData() {
      try {
        await this.$store.dispatch('getMyUserData'),
        await this.$store.dispatch('listMyAccounts'),
        console.log("retriving data");
        this.my_user = this.$store.getters.getMyAWSUser;
        this.accounts_alias = this.$store.getters.getMyAccounts;
        try {
          this.accounts = this.$store.getters.getMyAWSAccounts
        }
        catch (error){
          this.accounts = []
        }
      } catch (error) {
        console.log("Error loading Data...", error);
      } finally {
        this.loading = false;
      }
    },
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#aws_config')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('AWS Config was copied ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      window.getSelection().removeAllRanges()
    },
    getSwitchRoleURL(roleArn){
      var base_url = "https://signin.aws.amazon.com/switchrole?"
      var account = "account="+roleArn.split("::")[1].split(":")[0]+"&"
      var role = "roleName=man/"+roleArn.split("/")[2]
      return (base_url + account + role)
    }
  },

};
</script>

<style>
.darkmode.modal {
  background-color: var(--groupui-man-color-anthracite-600);
  color: var(--groupui-man-color-silver-50);
}

body.darkmode .modal-content {
  background-color: var(--groupui-man-color-anthracite-600);
  color: var(--groupui-man-color-silver-50);
}
body.darkmode .form-control-plaintext {
  color: var(--groupui-man-color-silver-50);
  font-family: var(--groupui-man-font-family);
}
</style>
<style scoped>
a#config_download {
  margin-left: 1em;
}
.users {
  height: 100%;
  font-size: x-large;
}
.arn {
  height: 100%;
}
.red-text {
  color: var(--groupui-man-color-red-500-primary);
}
</style>
